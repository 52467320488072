import React, { Component } from 'react';
import axios from 'axios';
import {
  CompareCoachability,
  CompareCognition,
  CompareDerailers,
  CompareForm,
  CompareFunctionPositionFit,
  CompareLeadershipSuccess,
  EstimateGraph,
  PersonaGraph,
  SectionTitle
} from './components';
import compareDataFormatter from './helpers/compareDataFormatter';

export default class CompareReport extends Component {
  state = {
    companyName: '',
    reportData: []
  };

  onSubmit = (formData) => {
    this.setState({ reportData: [] });

    const postData = {
      op_id: formData.opId,
      calculation_container_ids: formData.calculationContainerIds
    };

    axios
      .post(`/compare-report/`, postData)
      .then(({ data }) => {
        this.setState({
          companyName: data ? data[0].company.name : '',
          reportData: [...data]
        });
      })
      .catch((err) => {
        this.setState({
          reportData: []
        });
      });
  };

  render() {
    const { admin } = this.props;
    const data = compareDataFormatter(this.state.reportData);

    return (
      <div>
        <CompareForm
          onSubmit={this.onSubmit}
          clearData={() => this.setState({ reportData: [] })}
          admin={admin}
        />
        {data && (
          <div>
            <SectionTitle title="Derailers" />
            <CompareDerailers employees={data.derailerData} />
            <SectionTitle title="Chance of Leadership Success" />
            <CompareLeadershipSuccess employees={data.clsData} />
            <SectionTitle title="Function Position Fit" />
            <CompareFunctionPositionFit employees={data.fpfData} />
            <SectionTitle title="Coachability" />
            <CompareCoachability employees={data.arData} />
            <SectionTitle title="Estimated Leadership Ascendency" />
            <EstimateGraph
              employees={data.estimateData}
              showNames
              companyName={this.state.companyName}
            />
            <SectionTitle title="Cognition" />
            <div className="compare-sliders">
              <div>
                <h3>Abstract</h3>
                <h3>Practical</h3>
              </div>
              {data.sliderData.map((employee, i) => (
                <CompareCognition
                  key={i}
                  name={employee.name}
                  value={employee.result}
                  adjustedValue={employee.adjusted_result}
                  color={employee.color}
                />
              ))}
            </div>
            <div className="compare-slider-legend">
              {data.sliderData.map((employee, i) => (
                <p key={i}>
                  <span style={{ color: employee.color }}>&#x2666;</span>
                  {employee.name}
                </p>
              ))}
            </div>
            <SectionTitle title="Leadership Expression" />
            <PersonaGraph data={data.leaderData} />
          </div>
        )}
      </div>
    );
  }
}

CompareReport.defaultProps = {
  admin: false
};
